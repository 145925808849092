import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title="404: Not found" />
      <section className="w-full md:w-3/4 flex flex-col items-center px-3">
        <article className="w-full flex flex-col border border-gray-400 mb-3 bg-white rounded">
          <div className="flex flex-col justify-start p-3 md:p-6">
            {/* Title */}
            <h1 className="text-3xl font-bold hover:text-gray-700 pb-6">
              404: Not found
            </h1>
            {/* 記事本文 */}
            <p className="pb-3">{intl.formatMessage({ id: "404.desc" })}</p>
            <img src="/images/404.png" alt="404" className="rounded"></img>
          </div>
        </article>
      </section>
    </Layout>
  )
}

export default NotFoundPage
